import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';


export default class InfiniteScrollBoolean extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     triggered: false,
        // };
    }

    render() {
        const {id, setProps} = this.props;

        return (
            <div id={id} >
                <Waypoint
                onEnter={e => setProps({ triggered: true })}
                />
            </div>
        );
    }
}

InfiniteScrollBoolean.propTypes = {
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * boolean, indicates whether the waypoint has been triggered
     */
	triggered: PropTypes.bool,
	
	/**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};

InfiniteScrollBoolean.defaultProps = {
    /**
     * Trigger defaulted to false
     */
	triggered: false
};